import React from 'react';

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';

import Cancel from '@material-ui/icons/Cancel';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ChipInput from 'material-ui-chip-input';
import Button from '@material-ui/core/Button';

import moment from 'moment';
import {TimeRange, TimeSeries} from "pondjs";
import {ChartContainer, ChartRow, Charts, LineChart, Resizable, styler, YAxis} from "react-timeseries-charts";

import AppSettings from '../../AppSettings';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";

const axios = require('axios')

const paletteColors = ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf', '#999999', '#8FBC8B', '#D2B48C', '#20B2AA', '#B0C4DE', '#DDA0DD', '#9C9AFF', '#9C3063', '#630063', '#FF8284', '#0065CE', '#000084', '#FF00FF', '#FFFF00', '#00FFFF', '#840084', '#840000', '#008284', '#0000FF', '#00CFFF', '#CEFFFF', '#CEFFCE', '#FFFF9C', '#9CCFFF', '#FF9ACE', '#CE9AFF', '#FFCF9C', '#3165FF', '#31CFCE', '#9CCF00', '#FFCF00', '#FF9A00', '#FF6500'];
const autoSuggestCumulative_options = ["No", "Yes", "Reset"];
const rating_options = [{name: 'hour', value: 3600}, {name: 'min', value: 60}, {name: 'sec', value: 1}];
const aggregation_options = ['default', 'mean', 'sum', 'min', 'max'];

function createData(id, name, unit, cumulative, conversions) {
    return {id, name, unit, cumulative, conversions};
}

const axisStyle = {
    labelFont: "montserrat",
    labelColor: "#BBBBBB",
}

const getTimeseriesFromPayload = (id, name, payload) => {
    let ts = new TimeSeries({
        name: name,
        columns: ["time", id],
        points: Object.keys(payload)
            .map(k => parseInt(k, 10))
            .sort()
            .map(k => [k, payload[k]])
    });
    return ts.clean(id);
}

const getTimeseriesFromAmplitude = amplitude => (
    getTimeseriesFromPayload(amplitude.id, amplitude.tagname, amplitude.payload)
)

const getMinFromPayload = payload => Math.min(...Object.values(payload))
const getMaxFromPayload = payload => Math.max(...Object.values(payload))

const getPayloadFromTimeseries = (ts) => {
    return Object.assign(...Array.from(ts.events()).map(e => ({[e.begin().getTime()]: e.get(ts.columns()[0])})))
}

const filterStatsforAxisRange = (stats, key) => {
    return stats.filter(t => (t.amplitude >= key && t.amplitude < key * 8))
}

const getAxisSets = (rawTrajectories, rawPayloads) => {
    const stats = rawTrajectories.filter(rt => rawPayloads.get(rt.tagname)).map(rawTrajectory => (
        {
            id: rawTrajectory.id.replaceAll("#", "_").replaceAll("/", "_").replaceAll(".", "_").replaceAll("-", "_"),
            tagname: rawTrajectory.tagname,
            payload: rawPayloads.get(rawTrajectory.tagname),
            min_abs: Math.min(...Object.values(rawPayloads.get(rawTrajectory.tagname)).map(v => Math.abs(v))),
            max_abs: Math.max(...Object.values(rawPayloads.get(rawTrajectory.tagname)).map(v => Math.abs(v))),
            amplitude: Math.max(...Object.values(rawPayloads.get(rawTrajectory.tagname)).map(v => Math.abs(v))) - Math.min(...Object.values(rawPayloads.get(rawTrajectory.tagname)).map(v => Math.abs(v))),
        }));
    const min_amplitude = Math.min(...stats.map(a => a.amplitude));
    const max_amplitude = Math.max(...stats.map(a => a.amplitude));

    var magnitude_sets = [];
    var counter = 0;
    for (var key = min_amplitude; key <= max_amplitude * 8; key = key * 8) {
        const selection = filterStatsforAxisRange(stats, key);
        if (selection.length > 0) {
            magnitude_sets.push({
                key: key,
                min: Math.min(...selection.map(t => getMinFromPayload(t.payload))),
                max: Math.max(...selection.map(t => getMaxFromPayload(t.payload))),
                selection: selection,
            })
        }
        counter++;
        if (counter > 20) {
            break;
        }
    }

    const result = magnitude_sets.map(magnitude_set => ({
        name: "ampl_" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + "_" + magnitude_set.key,
        min: magnitude_set.min,
        max: magnitude_set.max,
        columns: magnitude_set.selection.map(a => a.id),
        legend_categories: magnitude_set.selection.map(a => ({
            key: a.id,
            label: a.tagname,
        })),
        series: TimeSeries.timeSeriesListMerge({
            name: "data",
            seriesList: magnitude_set.selection.map(getTimeseriesFromAmplitude)
        }),
        interactive: true,
    }));
    return result;
}

const handleHttpResponse = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    } else {
        return null
    }
}

const getHttpJsonAuthAsync = async (url) => {
    const params = {
        "headers": {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.sqb_auth0_access_token}`
        }
    };
    try {
        const response = await axios.get(url, params);
        return handleHttpResponse(response);
    } catch {
        return null
    }
}

const postHttpJsonAuthAsync = async (url, postData) => {
    const params = {
        "headers": {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.sqb_auth0_access_token}`
        }
    };
    try {
        const response = await axios.post(url, JSON.stringify(postData), params);
        return handleHttpResponse(response);
    } catch {
        return null
    }
}

const getProtocolsRawTrajectories = async (protocols, projectID) => {
    return Promise.all(protocols.map(protocol => {
        const cleaned_protocol = protocol.replace('-20190316', '');
        return getHttpJsonAuthAsync(AppSettings.API_BASE_URL + "/file/parameters/" + projectID + "?protocol=" + cleaned_protocol + "&name=parameters-" + cleaned_protocol + ".json");
    }))
}

class ConfigTagSelection extends React.Component {

    constructor(props, context) {
        super(props, context);
        this._isMounted = false;

        this.chipInput = null;
        this.setChipInputRef = element => {
            this.chipInput = element;
        };
        this.setChipInputFocus = () => {
            if (this.chipInput) this.chipInput.focus();
        };

        this.state = {
            timeRange: TimeRange.lastThirtyDays(),
            nameFilterChips: this.props.nameFilterChips && this.props.nameFilterChips.length > 0
                ? this.props.nameFilterChips
                : (typeof localStorage !== 'undefined')
                    ? JSON.parse(localStorage.getItem("nameFilterChips"))
                    : [],
            unitFilterChips: [],
            valueFilterChips: [],
            order: 'asc',
            orderBy: 'name',
            visibleIds: [],
            aggregation: this.props.aggregation,
            allTrajectories: [],
            selectedTrajectories: [],
            ratedTrajectories: [],
            userInput: new Map(),
            rawPayloads: new Map(),
            allData: [],
            axisSets: [],
            rowsPerPage: 10,
            page: 0,
            areTagsShown: false,
        };
    }

    handleTimeRangeChange = timeRange => {
        this._isMounted && this.setState({timeRange});
    };

    async componentDidMount() {
        this._isMounted = true;

    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    handleProps = async (props) => {
        if (!props.configTagSelectionQuery.loading && !props.configTagSelectionQuery.error) {

            //..............................
            // Get RTs from Prisma
            //..............................

            const prismaTrajectories = props.configTagSelectionQuery.data.building.rawTrajectories.map(t => t)

            //---------------------------------------------------------
            // Possible driver names
            //---------------------------------------------------------
            const possibleProtocols = ["bacnet", "bacnet_1", "bacnet_2", "bacnet_3", "bacnet_4", "knx", "daikin_http", "opc_ua", "cumulocity", "modbus", "linksper", "airthings", "linksper-http", "schneider-http"]
            const activeProtocols = props.configTagSelectionQuery.data.building.buildingFeatures.map(bf => bf.feature.name).filter(value => possibleProtocols.includes(value)).map(protocol => protocol + "-wizard")
            //..............................
            // Get RTs from S3
            //..............................

            //const parametersFile = props.configTagSelectionQuery.data.building.parametersFiles.filter(pf => pf.protocol==="bacnet-RawTrajectories")[0];
            const s3_RTs = (await getProtocolsRawTrajectories(activeProtocols, props.configTagSelectionQuery.data.building.projectID)).flat().filter(value => Boolean(value) !== false)
            const s3Trajectories = s3_RTs ? s3_RTs.map(rt => ({
                "id": "s3-" + rt.tagname,
                "tagname": rt.tagname,
                "payload": {}
            })) : [];

            //..............................
            // Combine RTs
            //..............................

            const allTrajectories = prismaTrajectories.concat(s3Trajectories.filter(rt1 => !prismaTrajectories.map(rt2 => rt2.tagname).includes(rt1.tagname))); // Merge only those RTs with IDs not present in prisma
            this._isMounted && this.setState({allTrajectories});

            //..............................
            // Process RTs
            //..............................

            const allData = allTrajectories
                .map(trajectory =>
                    createData(
                        trajectory.id,
                        trajectory.tagname,
                        trajectory.autoSuggestUnit,
                        trajectory.autoSuggestCumulative + trajectory.autoSuggestCounterResetValue ? " (" + trajectory.autoSuggestCounterResetValue + ")" : "",
                        trajectory.systemMetricConversionsOut ? trajectory.systemMetricConversionsOut.length : 0,
                    ))
            this.handleNameFilterChipsChange(this.state.nameFilterChips || [], allData)
            this._isMounted && this.setState({allData});

            if (props.selectedTrajectoriesUserInput) {
                await this.selectTrajectories(Array.from(props.selectedTrajectoriesUserInput.keys()));
            }
            this.setChipInputFocus();
        }
    }

    handleNameFilterChipsAdd = (chip) => {
        let temp = this.state.nameFilterChips.slice();
        temp.push(chip);
        this.handleNameFilterChipsChange(temp, this.state.allData);
    }
    handleNameFilterChipsDelete = (chip, index) => {
        let temp = this.state.nameFilterChips.slice();
        temp.splice(index, 1);
        this.handleNameFilterChipsChange(temp, this.state.allData);
    }

    filterDataForFilterChips = (data, chip) => {
        return data.filter(item => (item.name.toLowerCase().search(chip.toLowerCase()) > -1))
    }

    handleNameFilterChipsChange = (nameFilterChips, allData) => {
        this._isMounted && this.setState({nameFilterChips});
        var visibleData = allData;
        for (var i = 0; i < nameFilterChips.length; i++) {
            visibleData = this.filterDataForFilterChips(visibleData, nameFilterChips[i]);
        }
        this._isMounted && this.setState({visibleIds: visibleData.map(item => item.id)});
    }
    handleAccordionChange = async (event, isExpanded) => {
        this.setState({areTagsShown: isExpanded});
        if (isExpanded) {
            await this.handleProps(this.props);
        }
    }
    /**
     * Gets rawMetricsTimeseries from api.deltaq.io based on selectedTrajectories.  The query is only
     * performed when the number of selectedTrajectories is between 0 and 10.
     * @param selectedTrajectories
     * @param projectID
     * @returns {Promise<void>}
     */
    handlePayloadFetch = async (selectedTrajectories, projectID) => {
        if (selectedTrajectories.length > 0 && selectedTrajectories.length < 10) {
            const startDateString = moment().subtract(7, 'days').format('YYYY-MM-DD');
            const endDateString = moment().format('YYYY-MM-DD');
            const tag_string = "\"" + selectedTrajectories.map(rt => rt.tagname).join("\",\"") + "\"";
            const query = "query { rawMetricsTimeseries ( projectId: \"" + projectID + "\", start: \"" + startDateString + "\", end:\"" + endDateString + "\", tagnames:[" + tag_string + "]){ payload }}";
            const response = await postHttpJsonAuthAsync(AppSettings.API_BASE_URL + "/ts", {"query": query});
            if (response) {
                var rawPayloads = new Map(Array.from(this.state.rawPayloads.entries()));
                for (const [key, value] of Object.entries(JSON.parse(response.data.rawMetricsTimeseries.payload))) {
                    rawPayloads.set(key, value);
                }
                this._isMounted && this.setState({rawPayloads});
            }
        }
    }

    rateTrajectories = (selectedTrajectories, userInput) => (
        selectedTrajectories.map(t => {
            var new_t = JSON.parse(JSON.stringify(t));
            if (userInput.get(t.id).has('cumulative') && userInput.get(t.id).get('cumulative') !== "No" && userInput.get(t.id).has('rating') && userInput.get(t.id).get('rating') !== 0) {
                new_t.payload = getPayloadFromTimeseries(
                    getTimeseriesFromPayload(t.id, t.id, this.state.rawPayloads.get(t.tagname))
                        .rate({fieldSpec: [t.id], allowNegative: false})
                        .clean(t.id + '_rate')
                        .map(e => e.setData({data: e.get(t.id + '_rate') * userInput.get(t.id).get('rating')}))
                );
            } else {
                new_t.payload = this.state.rawPayloads.get(t.tagname);
            }
            return new_t;
        })
    )

    selectTrajectories = async (selectedTrajectoryIds) => {
        const selectedTrajectories = Array.from(this.state.allTrajectories).filter(t => (selectedTrajectoryIds.indexOf(t.id) > -1));
        this._isMounted && this.setState({selectedTrajectories});

        await this.handlePayloadFetch(selectedTrajectories, this.props.projectID)

        var userInput = new Map(Array.from(this.state.userInput.entries()));
        selectedTrajectoryIds.forEach(id => {
            if (!this.state.userInput.has(id)) {
                const selectedTrajectory = this.props.configTagSelectionQuery.data.building.rawTrajectories.find(t => t.id === id);
                if (typeof selectedTrajectory == 'undefined') {
                    userInput.set(id, new Map([
                        ["unit", this.props.systemMetricTemplate.metric.defaultDisplayUnitString],
                        ["cumulative", "No"],
                        ["rating", 3600],
                        ["resetValue", 0],
                    ]));
                } else {
                    userInput.set(id, new Map([
                        ["unit", selectedTrajectory.userInputUnit ? selectedTrajectory.userInputUnit : selectedTrajectory.autoSuggestUnit ? selectedTrajectory.autoSuggestUnit : this.props.systemMetricTemplate.metric.defaultDisplayUnitString],
                        ["cumulative", selectedTrajectory.userInputCumulative !== null ? (selectedTrajectory.userInputCumulative ? selectedTrajectory.userInputCounterResetValue ? "Reset" : "Yes" : "No") : selectedTrajectory.autoSuggestCumulative ? selectedTrajectory.autoSuggestCumulative : "No"],
                        ["rating", selectedTrajectory.userInputRating ? selectedTrajectory.userInputRating : 3600],
                        ["resetValue", selectedTrajectory.userInputCounterResetValue ? selectedTrajectory.userInputCounterResetValue : selectedTrajectory.autoSuggestCounterResetValue ? selectedTrajectory.autoSuggestCounterResetValue : 0],
                    ]));
                }
            }
        })
        this._isMounted && this.setState({userInput});

        const ratedTrajectories = this.rateTrajectories(selectedTrajectories, userInput);
        this._isMounted && this.setState({ratedTrajectories});
        if (ratedTrajectories.length > 0) {
            this._isMounted && this.setState({
                timeRange: new TimeRange([
                    moment.min(...ratedTrajectories.filter(t => t.payload).map(t => moment.min(Object.keys(t.payload).map(m => moment(parseInt(m, 10)))))).toDate(),
                    moment.max(...ratedTrajectories.filter(t => t.payload).map(t => moment.max(Object.keys(t.payload).map(m => moment(parseInt(m, 10)))))).toDate(),
                ])
            })
        }
        const axisSets = getAxisSets(ratedTrajectories, this.state.rawPayloads);
        this._isMounted && this.setState({axisSets});
    }

    handleRemoveTrajectory = (id) => () => {
        this.handleClick(null, id);
    }

    handleClick = async (event, id) => {
        const selectedIds = this.state.selectedTrajectories.map(t => t.id);
        const selectedIndex = selectedIds.indexOf(id);
        let newSelectedIds = [];

        if (selectedIndex === -1) {
            newSelectedIds = newSelectedIds.concat(selectedIds, id);
        } else if (selectedIndex === 0) {
            newSelectedIds = newSelectedIds.concat(selectedIds.slice(1));
        } else if (selectedIndex === selectedIds.length - 1) {
            newSelectedIds = newSelectedIds.concat(selectedIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedIds = newSelectedIds.concat(
                selectedIds.slice(0, selectedIndex),
                selectedIds.slice(selectedIndex + 1),
            );
        }

        await this.selectTrajectories(newSelectedIds);
    };

    handleChangePage = (event, page) => {
        this._isMounted && this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this._isMounted && this.setState({rowsPerPage: event.target.value});
    };

    handleAggregationChange = event => {
        this._isMounted && this.setState({aggregation: event.target.value})
    }

    handleTrajectoryChange = (id, param) => event => {
        var userInput = new Map(Array.from(this.state.userInput.entries()));
        if (userInput.has(id)) {
            userInput.get(id).set(param, event.target.value);
        } else {
            userInput.set(id, new Map([[param, event.target.value]]));
        }
        this._isMounted && this.setState({userInput});

        if (param === "cumulative" || param === "rating") {
            this.selectTrajectories(this.state.selectedTrajectories.map(t => t.id));
        }
    }

    isVisible = id => this.state.visibleIds.indexOf(id) !== -1;
    isSelected = id => this.state.selectedTrajectories.map(t => t.id).indexOf(id) !== -1;
    getColor = id => paletteColors[
        this.state.selectedTrajectories.map(t => t.id).indexOf(id) >= 0
            ? this.state.selectedTrajectories.map(t => t.id).indexOf(id)
            : this.state.selectedTrajectories.map(t => t.id.replaceAll("#", "_").replaceAll("/", "_").replaceAll(".", "_").replaceAll("-", "_")).indexOf(id)
        ];

    handleCancel = () => {
        this.props.onCancel();
    }

    handleSave = () => {
        this.props.onSave(this.state.selectedTrajectories, this.state.userInput, this.state.aggregation, this.state.nameFilterChips);
    }

    render() {
        const {allData, visibleIds, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, allData.length - page * rowsPerPage);

        return (
            <Paper style={{padding: 25}}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Accordion TransitionProps={{unmountOnExit: true}} onChange={this.handleAccordionChange}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography>Tag Selection</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    this.props.configTagSelectionQuery.error ? (
                                        <Typography gutterBottom key="lbl_error">Error fetching parameters!
                                        </Typography>
                                    ) : this.props.configTagSelectionQuery.loading ? ([
                                        <Typography gutterBottom key="lbl_fetching_building">
                                            Fetching Building Management data.<br/>
                                            Please note that, depending on the amount of data, this can take a
                                                moment...</Typography>,
                                        <CircularProgress key="loadingprogress" size={50} color="secondary"/>
                                    ]) : (this.state.allData.length === 0) ? ([
                                        <Typography gutterBottom key="lbl_parameters_loaded">
                                            Parameters loaded.<br/>
                                            Parsing data...
                                        </Typography>
                                    ]) : (
                                        <Grid>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell size="small"></TableCell>
                                                        <TableCell size="small">Tag Name</TableCell>
                                                        <TableCell size="small">Cumul</TableCell>
                                                        <TableCell size="small">Unit</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell size="small">where:</TableCell>
                                                        <TableCell size="small" colSpan={5}>
                                                            <ChipInput
                                                                value={this.state.nameFilterChips}
                                                                onAdd={(chip) => this.handleNameFilterChipsAdd(chip)}
                                                                onDelete={(chip, index) => this.handleNameFilterChipsDelete(chip, index)}
                                                                inputRef={this.setChipInputRef}
                                                                autoFocus
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {allData
                                                        .filter(item => this.isVisible(item.id))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map(n => {
                                                            const isSelected = this.isSelected(n.id);
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    onClick={event => this.handleClick(event, n.id)}
                                                                    role="checkbox"
                                                                    aria-checked={isSelected}
                                                                    tabIndex={-1}
                                                                    key={n.id}
                                                                    selected={isSelected}
                                                                    style={{backgroundColor: this.getColor(n.id)}}
                                                                >
                                                                    <TableCell padding="checkbox">
                                                                        <Checkbox checked={isSelected}/>
                                                                    </TableCell>
                                                                    <TableCell padding="none"
                                                                               style={{fontWeight: n.id.substr(0, 3) !== "s3-" ? "bold" : ""}}>{n.name + (n.conversions > 0 ? " [" + n.conversions + " ☑]" : "")}</TableCell>
                                                                    <TableCell size="small"
                                                                               align="right">{n.unit}</TableCell>
                                                                    <TableCell size="small"
                                                                               align="right">{n.cumulative}</TableCell>
                                                                    {/* <TableCell size="small" align="right">{Math.abs(n.min) > 1000000 ? parseInt(n.min/1000000, 10) + "M" : Math.abs(n.min) > 1000 ? parseInt(n.min/1000, 10) + "k" : Number.isInteger(n.min) ? n.min : n.min.toPrecision(4)}</TableCell> */}
                                                                    {/* <TableCell size="small" align="right">{Math.abs(n.max) > 1000000 ? parseInt(n.max/1000000, 10) + "M" : Math.abs(n.max) > 1000 ? parseInt(n.max/1000, 10) + "k" : Number.isInteger(n.max) ? n.max : n.max.toPrecision(4)}</TableCell> */}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRows > 0 && (
                                                        <TableRow style={{height: 49 * emptyRows}}>
                                                            <TableCell colSpan={4}/>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            colSpan={4}
                                                            count={visibleIds.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onChangePage={this.handleChangePage}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </Grid>
                                    )
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{textAlign: "center"}}>
                        {
                            this.state.axisSets.length > 0
                                ? (
                                    <Resizable>
                                        <ChartContainer timeRange={this.state.timeRange} TimeAxisStyle={axisStyle}
                                                        enablePanZoom={true}
                                                        onTimeRangeChanged={this.handleTimeRangeChange}
                                        >
                                            {this.state.axisSets.map(as => (
                                                <ChartRow key={as.name}>
                                                    <YAxis id={as.name} width="60" min={as.min * 0.9} max={as.max * 1.1}
                                                           style={axisStyle}/>
                                                    <Charts>
                                                        <LineChart
                                                            axis={as.name}
                                                            series={as.series}
                                                            columns={as.columns}
                                                            style={styler(as.columns.map(id => ({
                                                                key: id,
                                                                color: this.getColor(id),
                                                                width: 1,
                                                            })))}
                                                            smooth={false}
                                                            breakLine={false}
                                                        />
                                                    </Charts>
                                                </ChartRow>
                                            ))}
                                        </ChartContainer>
                                    </Resizable>
                                ) : (
                                    <Typography gutterBottom></Typography>
                                )
                        }
                        <Typography variant="h6" gutterBottom>&nbsp;</Typography>
                        <Typography variant="h6"
                                    gutterBottom>{this.props.systemDisplayName} - {this.props.systemMetricTemplate.displayName}</Typography>
                        <Divider/>
                        <Table>
                            <TableBody>
                                {
                                    this.state.ratedTrajectories.map(trajectory => ([
                                        <TableRow
                                            tabIndex={-1}
                                            key={trajectory.id}
                                        >
                                            <TableCell size="small" colSpan={5} style={{
                                                color: this.getColor(trajectory.id),
                                                borderBottom: "0px"
                                            }}>
                                                {trajectory.tagname}
                                            </TableCell>
                                        </TableRow>,
                                        <TableRow
                                            tabIndex={-1}
                                            key={trajectory.id + "_settings"}
                                        >
                                            <TableCell size="small" style={{borderBottom: "0px"}}>
                                                <TextField
                                                    id={"unit_" + trajectory.id}
                                                    select
                                                    helperText="Unit"
                                                    value={this.state.userInput.get(trajectory.id).get("unit")}
                                                    onChange={this.handleTrajectoryChange(trajectory.id, 'unit')}
                                                    margin="normal"
                                                >
                                                    <MenuItem
                                                        key={this.props.systemMetricTemplate.metric.defaultDisplayUnitString}
                                                        value={this.props.systemMetricTemplate.metric.defaultDisplayUnitString}>
                                                        {this.props.systemMetricTemplate.metric.defaultDisplayUnitString}
                                                    </MenuItem>
                                                    {this.props.systemMetricTemplate.metric.alternativeUnitStrings.map(option => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </TableCell>
                                            <TableCell size="small" style={{borderBottom: "0px"}}>
                                                <TextField
                                                    id={"cumulative_" + trajectory.id}
                                                    select
                                                    helperText="Cumulative"
                                                    value={this.state.userInput.get(trajectory.id).get("cumulative")}
                                                    onChange={this.handleTrajectoryChange(trajectory.id, 'cumulative')}
                                                    margin="normal"
                                                >
                                                    {autoSuggestCumulative_options.map(option => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </TableCell>
                                            <TableCell size="small" style={{borderBottom: "0px"}}>
                                                <TextField
                                                    id={"rating_" + trajectory.id}
                                                    select
                                                    helperText="Rating"
                                                    value={this.state.userInput.get(trajectory.id).get("rating")}
                                                    onChange={this.handleTrajectoryChange(trajectory.id, 'rating')}
                                                    disabled={this.state.userInput.get(trajectory.id).get("cumulative") === "No"}
                                                    margin="normal"
                                                >
                                                    {rating_options.map(option => (
                                                        <MenuItem key={option.name} value={option.value}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </TableCell>
                                            <TableCell size="small" style={{borderBottom: "0px"}}>
                                                <TextField
                                                    id={"resetValue_" + trajectory.id}
                                                    helperText="Reset Value"
                                                    value={this.state.userInput.get(trajectory.id).get("resetValue")}
                                                    onChange={this.handleTrajectoryChange(trajectory.id, 'resetValue')}
                                                    disabled={this.state.userInput.get(trajectory.id).get("cumulative") !== "Reset"}
                                                    style={{width: 75}}
                                                    margin="normal"
                                                />
                                            </TableCell>
                                            <TableCell size="small" style={{borderBottom: "0px"}}>
                                                <Button variant="contained" size="small" color="inherit"
                                                        onClick={this.handleRemoveTrajectory(trajectory.id)}>
                                                    <Delete/>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ]))
                                }
                            </TableBody>
                        </Table>
                        <Divider/>
                        <Toolbar style={{margin: "auto"}}>
                            <Typography color="inherit" style={{flex: 1}}>
                                &nbsp;
                            </Typography>
                            <Typography color="inherit" style={{fontSize: "16px"}}>
                                Tag Aggregation Method:
                            </Typography>
                            <Typography color="inherit">
                                &nbsp;&nbsp;&nbsp;
                            </Typography>
                            <TextField
                                id="select-aggregation"
                                select
                                value={this.state.aggregation}
                                onChange={this.handleAggregationChange}
                                margin="none"
                            >
                                {aggregation_options.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Typography color="inherit" style={{flex: 1}}>
                                &nbsp;
                            </Typography>
                        </Toolbar>
                        <Toolbar style={{margin: "auto"}}>
                            <Typography color="inherit" style={{flex: 1}}>
                                &nbsp;
                            </Typography>
                            <Button variant="contained" size="small" color="inherit" onClick={this.handleCancel}>
                                Cancel&nbsp;&nbsp;<Cancel/>
                            </Button>
                            <Typography color="inherit">
                                &nbsp;&nbsp;&nbsp;
                            </Typography>
                            <Button variant="contained" size="small" color="inherit" onClick={this.handleSave}>
                                Save&nbsp;&nbsp;<Save/>
                            </Button>
                            <Typography color="inherit" style={{flex: 1}}>
                                &nbsp;
                            </Typography>
                        </Toolbar>
                    </Grid>
                </Grid>

            </Paper>
        )
    }
}


export default ConfigTagSelection