import React from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import ProfileDayScheduleList from '../ProfileDayScheduleList'

class ConfigComponentProfiles extends React.Component {
  render() {
    return (
      <Paper>
        <ProfileDayScheduleList projectID={this.props.match.params.projectID} />
        <Typography component="div" style={{ padding: 8 * 3 }}>
          * Daily profiles, defined as either lists or JSON objects, defined per moment (e.g. "14:23") or period (e.g. "06:30-00:00").
        </Typography>
      </Paper>
    )
  }
}

export default ConfigComponentProfiles;
