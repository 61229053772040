import React, {useState} from 'react';
import {useMutation, useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import ConfigSystem from './ConfigSystem'
import ConfigSpace from './ConfigSpace'
import ConfigWaterCircuit from './ConfigWaterCircuit'
import useCreateSpace from "../../hooks/useCreateSpace";
import useCreateAirHandlingUnit from "../../hooks/useCreateAirHandlingUnit";
import useCreateWaterCircuit from "../../hooks/useCreateWaterCircuit";

const getNextSystemTemplateSlug = (systemTemplate, items) => {
    const systemSlugPrefix = systemTemplate.name.slice(systemTemplate.name.indexOf("_") + 1)
    const number = Math.max(...(items.length > 0 ? items.map(s => parseInt(s.slug ? s.slug.split('_').slice(-1).pop() : "0", 10)) : [0])) + 1;
    return systemSlugPrefix + "_" + (number <= 9999 ? ("000" + number).slice(-4) : number);
}

const ConfigSystemTemplate = props => {
    const {projectID, systemTemplateId, systemMetricTemplates} = props

    const [displayName, setDisplayName] = useState("")
    const {data, loading, error, refetch} = useQuery(getSystemsByTemplateId, {variables: {systemTemplateId, projectID}})
    const [createSystemMutation] = useMutation(createSystem)
    const {createSpace} = useCreateSpace()
    const {createAirHandlingUnit} = useCreateAirHandlingUnit()
    const {createWaterCircuit} = useCreateWaterCircuit()

    if (loading) return (<Grid><CircularProgress size={50} color="secondary"/></Grid>)
    if (error) return (<Grid><Typography gutterBottom>Error fetching SystemTemplate data!</Typography></Grid>)
    if (!data.systemTemplates || !data.systemTemplates.length) return (
        <Typography gutterBottom>No SystemTemplate Found!</Typography>)

    const systemTemplate = data.systemTemplates[0]

    const handleInstanceAdd = async (event) => {
        event.preventDefault();

        if (systemTemplate.name === "systemTemplate_space") {
            await createSpace({
                projectID: projectID,
                systemTemplateId: systemTemplate.id,
                displayName: displayName,
                slug: getNextSystemTemplateSlug(systemTemplate, systemTemplate.spaces),
            });
        } else if (systemTemplate.name === "systemTemplate_wc") {
            await createWaterCircuit({
                    projectID: projectID,
                    systemTemplateId: systemTemplate.id,
                    displayName: displayName,
                    slug: getNextSystemTemplateSlug(systemTemplate, systemTemplate.waterCircuits),

            });
        } else if (systemTemplate.name === "systemTemplate_ahu") {
            await createAirHandlingUnit({
                projectID: projectID,
                systemTemplateId: systemTemplate.id,
                displayName: displayName,
                slug: getNextSystemTemplateSlug(systemTemplate, systemTemplate.systems),

            })
        } else {
            await createSystemMutation({
                variables: {
                    projectID: projectID,
                    systemTemplateId: systemTemplate.id,
                    displayName: displayName,
                    slug: getNextSystemTemplateSlug(systemTemplate, systemTemplate.systems),
                },
            });
        }
        setDisplayName("");
        await refetch();
    };
    return (
        <Accordion key={systemTemplate.id} TransitionProps={{unmountOnExit: true}} defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant="h6" gutterBottom>{systemTemplate.displayName} List</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid style={{width: "100%"}}>
                    {systemTemplate.systems && systemTemplate.systems.map((system) => {
                        return (
                            <ConfigSystem
                                key={system.id}
                                projectID={projectID}
                                systemId={system.id}
                                systemMetricTemplates={systemMetricTemplates}
                                displayName={system.displayName}
                                parentRefetch={refetch}/>
                        )
                    })}
                    {systemTemplate.spaces && systemTemplate.spaces.map((space) => {
                        return (
                            <ConfigSpace
                                key={space.id}
                                projectID={projectID}
                                spaceId={space.id}
                                systemMetricTemplates={systemMetricTemplates}
                                displayName={space.displayName}
                                parentRefetch={refetch}/>
                        )
                    })}
                    {systemTemplate.waterCircuits && systemTemplate.waterCircuits.map((waterCircuit) => {
                        return (
                            <ConfigWaterCircuit
                                key={waterCircuit.id}
                                projectID={projectID}
                                waterCircuitId={waterCircuit.id}
                                systemMetricTemplates={systemMetricTemplates}
                                displayName={waterCircuit.displayName}
                                parentRefetch={refetch}/>
                        )
                    })}
                </Grid>
            </AccordionDetails>
            <Divider/>
            <AccordionActions>
                <FormControl>
                    <Input
                        id="displayName-input"
                        value={displayName}
                        onChange={(event) => {
                            setDisplayName(event.target.value)
                        }}
                    />
                </FormControl>
                <Button size="small" variant="contained" color="primary" onClick={(event) => handleInstanceAdd(event)}>
                    <Icon>add</Icon>&nbsp;&nbsp;&nbsp;Add New {systemTemplate.displayName}&nbsp;
                </Button>
            </AccordionActions>
        </Accordion>
    );
}

const createSystem = gql`
mutation createSystem($projectID: String!, $systemTemplateId: ID!, $displayName: String!, $slug: String!) {
  createSystem(
    displayName: $displayName
    slug: $slug
    projectID: $projectID
    systemTemplateId: $systemTemplateId
  ) { id }
}`;
const getSystemsByTemplateId = gql`
query getSystemTemplates($systemTemplateId: ID!, $projectID: String!) {
  systemTemplates (systemTemplateId: $systemTemplateId) {
    id
    name
    displayName
    systems (projectID: $projectID) {
      id
      displayName
      slug
    }
    spaces (projectID: $projectID) {
      id
      displayName
      slug
    }
    waterCircuits (projectID: $projectID) {
      id
      displayName
      slug
    }
  }
}`;

export default ConfigSystemTemplate;
