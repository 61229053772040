import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ConfigSystemTemplate from './ConfigSystemTemplate'
import {SystemMetricTagsProvider} from "../../contexts/systemMetricTagsContext";

const ConfigComponentCircuit = (props) => {
    const projectID = props.match.params.projectID
    const {data, loading, error} = useQuery(getSystemTemplatesForCircuits, {variables: {projectID}})

    if (loading) return (<Grid><CircularProgress size={50} color="secondary"/></Grid>)
    if (error) return (<Grid><Typography gutterBottom>Error fetching building configuration!</Typography></Grid>)
    if (!data.systemTemplates) return (<Typography gutterBottom>No Data Found!</Typography>)

    return (
        <SystemMetricTagsProvider>
            <div>
                <div>&nbsp;</div>
                {data.systemTemplates && data.systemTemplates.map((systemTemplate, index) => {
                    return (
                        <ConfigSystemTemplate
                            key={systemTemplate.id}
                            systemTemplateId={systemTemplate.id}
                            systemTemplate={systemTemplate}
                            systemMetricTemplates={systemTemplate.systemMetricTemplates}

                            projectID={projectID}/>
                    )
                })}
            </div>
        </SystemMetricTagsProvider>
    )
}

const getSystemTemplatesForCircuits = gql`
query getSystemTemplatesForCircuits {
  systemTemplates (systemTemplateNames: ["systemTemplate_wc"]) {
    id
    name
    displayName
    systemMetricTemplates(where:{level:0}){
      id
      displayName
      possibleFunctionTags{
        id
        key
        value
      }
      guiOrder
    }
  }
}`

export default ConfigComponentCircuit
