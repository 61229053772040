const SYSTEMMETRIC_TAG_FUNCTION_LIST = [
    {
        'slug': 'sensor#tag#',
        "displayName": "Sensor (BMS Tags)",
        "function": "sensor",
        "source": "tag",
        "timewindow": "past",
        "rwc": null
    },
    {
        'slug': 'nominal#value#',
        "displayName": "Nominal (Fixed)",
        "function": "nominal",
        "source": "value",
        "timewindow": "all",
        "rwc": null
    },
    {
        'slug': 'model#profile#control',
        "displayName": "Model (Profile)",
        "function": "model",
        "source": "profile",
        "timewindow": "all",
        "rwc": "control"
    },

    {
        'slug': 'setpoint#tag#read',
        "displayName": "Setpoint (BMS Read)",
        "function": "setpoint",
        "source": "tag",
        "timewindow": "past",
        "rwc": "read"
    },
    {
        'slug': 'setpoint#tag#write',
        "displayName": "Setpoint (BMS Write)",
        "function": "setpoint",
        "source": "tag",
        "timewindow": "past",
        "rwc": "write"
    },
    {
        'slug': 'setpoint#value#control',
        "displayName": "Setpoint (Control Value)",
        "function": "setpoint",
        "source": "value",
        "timewindow": "all",
        "rwc": "control"
    },
    {
        'slug': 'setpoint#profile#control',
        "displayName": "Setpoint (Control Profile)",
        "function": "setpoint",
        "source": "profile",
        "timewindow": "all",
        "rwc": "control"
    },
    {
        'slug': 'limit_low#tag#read',
        "displayName": "Low Limit (BMS Read)",
        "function": "limit_low",
        "source": "tag",
        "timewindow": "past",
        "rwc": "read"
    },
    {
        'slug': 'limit_low#tag#write',
        "displayName": "Low Limit (BMS Write)",
        "function": "limit_low",
        "source": "tag",
        "timewindow": "past",
        "rwc": "write"
    },
    {
        'slug': 'limit_low#value#control',
        "displayName": "Low Limit (Control Value)",
        "function": "limit_low",
        "source": "value",
        "timewindow": "all",
        "rwc": "control"
    },
    {
        'slug': 'limit_low#profile#control',
        "displayName": "Low Limit (Control Profile)",
        "function": "limit_low",
        "source": "profile",
        "timewindow": "all",
        "rwc": "control"
    },
    {
        'slug': 'alert_low#value#',
        "displayName": "Low Alert (Fixed)",
        "function": "alert_low",
        "source": "value",
        "timewindow": "past",
        "rwc": null
    },
    {
        'slug': 'alert_low#profile#',
        "displayName": "Low Alert (Profile)",
        "function": "alert_low",
        "source": "profile",
        "timewindow": "past",
        "rwc": null
    },
    {
        'slug': 'limit_high#tag#read',
        "displayName": "High Limit (BMS Read)",
        "function": "limit_high",
        "source": "tag",
        "timewindow": "past",
        "rwc": "read"
    },
    {
        'slug': 'limit_high#tag#write',
        "displayName": "High Limit (BMS Write)",
        "function": "limit_high",
        "source": "tag",
        "timewindow": "past",
        "rwc": "write"
    },
    {
        'slug': 'limit_high#value#control',
        "displayName": "High Limit (Control Value)",
        "function": "limit_high",
        "source": "value",
        "timewindow": "all",
        "rwc": "control"
    },
    {
        'slug': 'limit_high#profile#control',
        "displayName": "High Limit (Control Profile)",
        "function": "limit_high",
        "source": "profile",
        "timewindow": "all",
        "rwc": "control"
    },
    {
        'slug': 'alert_high#value#',
        "displayName": "High Alert (Fixed)",
        "function": "alert_high",
        "source": "value",
        "timewindow": "past",
        "rwc": null
    },
    {
        'slug': 'alert_high#profile#',
        "displayName": "High Alert (Profile)",
        "function": "alert_high",
        "source": "profile",
        "timewindow": "past",
        "rwc": null
    },
]


const getFunctionFromSlug = (slug) => (SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.slug === slug).function)
const getSourceFromSlug = (slug) => (SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.slug === slug).source)
const getTimewindowFromSlug = (slug) => (SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.slug === slug).timewindow)
const getRwcFromSlug = (slug) => (SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.slug === slug).rwc)
const getPayloadTypeFromSlug = (slug) => ((getSourceFromSlug(slug) === "value") ? "value" : "timeseries")
const getConfigWizardTagFromTags = (systemMetricTags) => systemMetricTags.filter(t => (t.key === "creator" && t.value === "configwizard")).map(t => (t.id))[0]
const getConfigForSlug = (slug) => (SYSTEMMETRIC_TAG_FUNCTION_LIST.find(f => f.slug === slug))
export {
    SYSTEMMETRIC_TAG_FUNCTION_LIST,
    getFunctionFromSlug,
    getSourceFromSlug,
    getTimewindowFromSlug,
    getRwcFromSlug,
    getPayloadTypeFromSlug,
    getConfigWizardTagFromTags,
    getConfigForSlug
}