import {useApolloClient, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {useSystemMetricTags} from "../contexts/systemMetricTagsContext";
import {getConfigWizardTagFromTags} from "../utils/systemMetricTags";

const CREATE_WATER_CIRCUIT = gql`
mutation createWaterCircuit($projectID: String!, $systemTemplateId: ID!, $displayName: String!, $slug: String!) {
  createWaterCircuit(
    displayName: $displayName
    slug: $slug
    projectID: $projectID
    systemTemplateId: $systemTemplateId
  ) { id }
}`;
const CREATE_SYSTEM_METRIC_ON_WATER_CIRCUIT =gql`
mutation createSystemMetricOnWaterCircuit($projectID: String!, $waterCircuitId: ID!, $systemMetricTemplateId: ID!, $payload: Json!, $userInput:Json!, $aggregation:String!, $systemMetricTags: [SystemMetricTagWhereUniqueInput!], $rawTrajectories: [RawTrajectoryWhereUniqueInput!], $profileContextCreateOneWithoutSystemMetricConversionsInput: ProfileContextCreateOneWithoutSystemMetricConversionsInput) {
  createSystemMetricOnWaterCircuit(
    projectID: $projectID
    waterCircuitId: $waterCircuitId
    systemMetricTemplateId: $systemMetricTemplateId
    payload: $payload
    userInput: $userInput
    aggregation: $aggregation
    systemMetricTags: $systemMetricTags
    rawTrajectories: $rawTrajectories
    profileContextCreateOneWithoutSystemMetricConversionsInput: $profileContextCreateOneWithoutSystemMetricConversionsInput
    ) { id }
}`;

const GET_WATER_CIRCUIT_SYSTEM_METRICS_TEMPLATES = gql`
query getAirHandlingUnitSystemMetrics {
  systemTemplates(systemTemplateNames: ["systemTemplate_wc"]){
    id
    name
    systemMetricTemplates(where: { level: 0 }) {
        id
        name
        description
        displayName
        metric{
            defaultOperationFormula
        }
    }
  }
}`;
const useCreateWaterCircuit = () => {
    const systemMetricTags = useSystemMetricTags();
    const [createSystemMutation] = useMutation(CREATE_WATER_CIRCUIT);
    const [createSystemMetricOnWaterCircuitMutation] = useMutation(CREATE_SYSTEM_METRIC_ON_WATER_CIRCUIT);
    const client = useApolloClient();

    const createWaterCircuit = async ({projectID, systemTemplateId, displayName, slug}) => {
        // Pump status - Sensor wc_binary_state_pump
        // Supply temperature - Sensor wc_temperature_water_supply
        // Return temperature - Sensor wc_temperature_water_return
        async function createSystemMetric(systemMetricTemplates, waterCircuitId, name) {
            const systemMetricTemplate = systemMetricTemplates.filter(t => (t.name === name))[0];
            const systemMetricTemplateId = systemMetricTemplate.id;
            const aggregation = systemMetricTemplate.metric.defaultOperationFormula;

            const systemMetricTagCreatorConfigwizardId = getConfigWizardTagFromTags(systemMetricTags)
            const sensorMetricTagId = systemMetricTags.filter(t => (t.key === "function" && t.value === "sensor"))[0].id;
            const timewindowTagId = systemMetricTags.filter(t => (t.key === "timewindow" && t.value === "past"))[0].id;
            const payloadType = "timeseries";
            await createSystemMetricOnWaterCircuitMutation({
                variables: {
                    projectID,
                    waterCircuitId: waterCircuitId,
                    systemMetricTemplateId: systemMetricTemplateId,
                    payload: {},
                    payloadType: payloadType,
                    userInput: {},
                    aggregation: aggregation,
                    systemMetricTags: [
                        {
                            "id": systemMetricTagCreatorConfigwizardId
                        },
                        {
                            "id": sensorMetricTagId
                        },
                        {
                            "id": timewindowTagId
                        }
                    ],
                    rawTrajectories: [],
                    profileContextCreateOneWithoutSystemMetricConversionsInput: null
                }
            });
        }




        const result = await createSystemMutation({
            variables: {
                projectID,
                systemTemplateId,
                displayName,
                slug,
            },
        });
        const waterCircuitId = result.data.createWaterCircuit.id;
        const systemMetricTemplates = await client.query({
            query: GET_WATER_CIRCUIT_SYSTEM_METRICS_TEMPLATES
        });
        const systemTemplate = systemMetricTemplates.data.systemTemplates[0];

        await createSystemMetric(systemTemplate.systemMetricTemplates, waterCircuitId,"wc_binary_state_pump");
        await createSystemMetric(systemTemplate.systemMetricTemplates,waterCircuitId, "wc_temperature_water_supply");
        await createSystemMetric(systemTemplate.systemMetricTemplates,waterCircuitId, "wc_temperature_water_return");
    }

    const createSystemMetricOnSystem = async (variables) => {
        await createSystemMetricOnWaterCircuitMutation({
            variables,
        });
    };


    return {createWaterCircuit, createSystemMetricOnSystem};
}

export default useCreateWaterCircuit;