// src/context/SystemMetricTagsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const SystemMetricTagsContext = createContext();

export const useSystemMetricTags = () => {
    return useContext(SystemMetricTagsContext);
};

const SYSTEM_METRIC_TAGS_QUERY = gql`
  query tags {
    systemMetricTags {
      id
      key
      value
    }
  }
`;

export const SystemMetricTagsProvider = ({ children }) => {
    const client = useApolloClient();
    const [systemMetricTags, setSystemMetricTags] = useState([]);

    useEffect(() => {
        const fetchSystemMetricTags = async () => {
            const { data } = await client.query({ query: SYSTEM_METRIC_TAGS_QUERY });
            setSystemMetricTags(data.systemMetricTags);
        };

        fetchSystemMetricTags();
    }, [client]);

    return (
        <SystemMetricTagsContext.Provider value={systemMetricTags}>
            {children}
        </SystemMetricTagsContext.Provider>
    );
};